import DarkCirclesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-appearance/dark-circles/DarkCirclesTemplate';

import { BOOLEAN, DARK_CIRCLE_COLOR } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinAppearance/DarkCircles/DarkCirclesTemplate',
  component: DarkCirclesTemplate
};

const createStory = props => () => ({
  components: { DarkCirclesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><dark-circles-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  darkCircles: BOOLEAN.YES,
  darkCircleColors: [
    DARK_CIRCLE_COLOR.HOLLOWED,
    DARK_CIRCLE_COLOR.PIGMENTED,
    DARK_CIRCLE_COLOR.BLUISH
  ],
  darkCircleColorsVisible: true,
  darkCirclesChildhood: BOOLEAN.YES,
  darkCirclesChildhoodVisible: true
});

export const required = createStory({
  darkCircles: BOOLEAN.NO
});

export const unselected = createStory({
  darkCircles: '',
  darkCircleColors: [],
  darkCircleColorsVisible: true
});

export const all = createStory({
  darkCircles: BOOLEAN.YES,
  darkCircleColors: [DARK_CIRCLE_COLOR.HOLLOWED],
  darkCircleColorsVisible: true
});

export const aiSelected = createStory({
  darkCircles: BOOLEAN.YES,
  darkCirclesAiSelected: BOOLEAN.YES,
  darkCircleColors: [DARK_CIRCLE_COLOR.HOLLOWED],
  darkCircleColorsVisible: true,
  darkCircleColorsAiSelected: [DARK_CIRCLE_COLOR.BLUISH]
});
